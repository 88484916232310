import React from "react";
import { motion } from "framer-motion";
import { SVGs } from "../assets/svg";

// Animation variants
const fadeInUp = {
  hidden: { opacity: 0, y: 50 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.8, ease: "easeOut" } },
};

const staggeredLinks = {
  hidden: { opacity: 0, y: 20 },
  visible: (i) => ({
    opacity: 1,
    y: 0,
    transition: { delay: i * 0.1, duration: 0.5, ease: "easeOut" },
  }),
};

// Reusable Link Component
const FooterLink = ({ label }) => (
  <motion.p
    className="text-[#AEAEAE] text-[14px] md:text-[16px] font-light hover:text-[#c4efea] transition-all ease-out"
    initial="hidden"
    animate="visible"
    variants={fadeInUp}
  >
    {label}
  </motion.p>
);

const Footer = () => {
  const businessLinks = [
    "Features",
    "Tokenomics",
    "Roadmap",
    "Platforms",
    "Whitepaper",
  ];
  const productLinks = [
    "Cloud",
    "RDP",
    "Web Development",
    "Hosting",
    "Domain Management",
    "Containerization",
  ];

  return (
    <motion.div
      className="flex flex-col bg-[#020913] px-6 md:px-16 py-8 md:py-12 w-full"
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true, amount: 0.3 }}
      variants={fadeInUp}
    >
      {/* Top Section */}
      <motion.div
        className="flex flex-col md:flex-row items-center justify-between py-4 w-full border-b-[1px] border-[#13fb73]"
        variants={fadeInUp}
      >
        <SVGs.FooterLogoSVG />
        <motion.div className="flex items-center gap-4 md:gap-8 mt-4 md:mt-0">
          <a
            href="https://twitter.com/?lang=en"
            target="_blank"
            rel="noreferrer"
          >
            <SVGs.TwitterSVG
              height={"28px"}
              width={"28px"}
              className="fill-[#878693] hover:fill-[#39CB75]"
            />
          </a>
          <a href="https://telegram.org/" target="_blank" rel="noreferrer">
            <SVGs.TelegramSVG
              height={"32px"}
              width={"32px"}
              className="fill-[#878693] hover:fill-[#39CB75]"
            />
          </a>
          <a href="https://dexscreener.com/" target="_blank" rel="noreferrer">
            <SVGs.DexScreenerSVG
              height={"32px"}
              width={"32px"}
              className="fill-[#878693] hover:fill-[#39CB75]"
            />
          </a>
          <a
            href="https://www.dextools.io/app/en"
            target="_blank"
            rel="noreferrer"
          >
            <SVGs.OtherIconSVG
              height={"32px"}
              width={"32px"}
              className="fill-[#878693] hover:fill-[#39CB75]"
            />
          </a>
        </motion.div>
      </motion.div>

      {/* Middle Section */}
      <div className="flex flex-row md:flex-nowrap flex-wrap mt-8 items-start justify-start relative w-full gap-8 md:gap-12">
        <SVGs.FooterIconSVG className="absolute right-0 bottom-0" />

        {/* Business Links */}
        <div className="flex flex-col gap-4">
          <p className="text-[#39CB75] text-[20px] md:text-[24px] font-extrabold tracking-wide">
            Business
          </p>
          {businessLinks.map((link, index) => (
            <FooterLink key={index} label={link} />
          ))}
        </div>

        {/* Product Links */}
        <div className="flex flex-col gap-4">
          <p className="text-[#39CB75] text-[20px] md:text-[24px] font-extrabold tracking-wide">
            Solutions
          </p>
          {productLinks.map((link, index) => (
            <FooterLink key={index} label={link} />
          ))}
        </div>
      </div>

      {/* Bottom Section */}
      <motion.p
        className="text-[12px] text-[#0b691e] w-full text-center mt-8"
        variants={fadeInUp}
      >
        Copyright © 2024 Sudo Labs - All Rights Reserved.
      </motion.p>
    </motion.div>
  );
};

export default Footer;
