import React, { useRef } from "react";
import { motion, useInView } from "framer-motion"; // Import Framer Motion for animations
import RoadmapGear from "../assets/svg/RoadmapGear.svg";
import RoadmapBlock from "../assets/svg/RoadmapBlock.svg";
import RoadmapBG from "../assets/svg/RoadmapBG.svg";

// Pop-in animation variants
const popInVariants = {
  hidden: { opacity: 0, scale: 0.8 },
  visible: (i) => ({
    opacity: 1,
    scale: 1,
    transition: { delay: i * 0.2, duration: 0.6, ease: "easeOut" },
  }),
};

const roadmapPhases = [
  {
    title: "Phase 1",
    milestones: [
      "Sudo Lab Telegram Bot V1 Launch",
      "$SUDO Token Launch",
      "Social Media Marketing",
      "Community Establishment",
      "Whitepaper V1 Release",
      "Listing on Uniswap",
    ],
  },
  {
    title: "Phase 2",
    milestones: [
      "Community Collaboration",
      "Sudo Lab DApp Launch",
      "Telegram Bot V2 Launch",
      "Listing on Prominent Platforms",
      "Liquidity Enhancement",
      "Long-Term Influencer Onboarding",
    ],
  },
  {
    title: "Phase 3",
    milestones: [
      "Official Release of DApp & Telegram Bot",
      "DApp Demo Release",
      "Whitepaper V2 Publication",
      "Strategic Sponsorship",
      "Listing on Centralized Exchanges (CEX)",
      "Partnership Expansion",
    ],
  },
  {
    title: "Phase 4",
    milestones: [
      "Community Collaboration",
      "Sudo Lab DApp Launch",
      "Telegram Bot V2 Launch",
      "Listing on Prominent Platforms",
      "Liquidity Enhancement",
      "Long-Term Influencer Onboarding",
    ],
  },
];

const PhaseCard = ({ phase, index }) => {
  const ref = useRef(null);
  const isInView = useInView(ref, {
    margin: "-30% 0px -40% 0px",
    once: false,
  });

  return (
    <motion.div
      key={index}
      className={`phase-card ${
        isInView && window.innerWidth <= 768 ? "phase-card-active" : ""
      }`}
      ref={ref}
      custom={index}
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true, amount: 0.3 }}
      variants={popInVariants} // Pop-in animation for roadmap phases
    >
      <p className="text-[26px] mb-4 font-semibold phase-title text-transparent text-start bg-clip-text bg-gradient-to-l">
        {phase.title}
      </p>
      {phase.milestones.map((milestone, i) => (
        <p
          key={i}
          className="text-[13px] relative font-normal text-[#c4efea] px-3 roadmap-bullets"
        >
          <span className="roadmap-bullets-tip"></span>
          {milestone}
        </p>
      ))}
    </motion.div>
  );
};

const Roadmap = () => {
  return (
    <motion.div
      className="relative flex flex-col md:flex-row w-full items-center justify-between px-4 md:px-8 lg:px-16 pt-8 md:pt-16 pb-36"
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true, amount: 0.3 }}
    >
      <img
        alt="roadmap-block"
        src={RoadmapBlock}
        className="absolute right-0 bottom-0 translate-x-[15%] translate-y-[50%] -z-40"
      />
      <img
        alt="roadmap-block"
        src={RoadmapBG}
        className="absolute right-0 bottom-0 -z-50 translate-y-[3rem]"
      />

      {/* Left Section */}
      <div className="flex flex-col md:flex-row items-stretch justify-start relative roadmap-box w-full">
        <img
          src={RoadmapGear}
          alt="roadmap-gear"
          className="absolute left-0 top-0 translate-y-[-75%] translate-x-[-25%] -z-50"
        />
        <div className="md:max-w-[350px] border-b-[1px] border-[#13fb73] md:border-r-[1px] flex flex-col items-center md:items-start justify-center gap-4 p-4 lg:p-8 w-full md:w-1/3">
          <p className="text-[13px] font-semibold text-[#13fb73]">Roadmap</p>
          <p className="text-[32px] font-semibold text-transparent text-center md:text-left bg-clip-text bg-gradient-to-l leading-tight">
            Project Milestones and Timelines
          </p>
          <p className="text-[13px] font-normal text-center md:text-left text-[#c4efea]">
            Explore our roadmap to see how we plan to shape the future of Sudo
            Lab. We believe in continuous growth and improvement to satisfy our
            community.
          </p>
        </div>

        {/* Roadmap Phases */}
        <div className="flex items-center p-4 lg:p-8 flex-grow justify-center w-full md:w-2/3">
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-8 sm:w-auto w-full">
            {roadmapPhases.map((phase, index) => (
              <PhaseCard phase={phase} index={index} />
            ))}
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default Roadmap;
