import { ReactComponent as HeaderLogoSVG } from "./HeaderLogo.svg";
import { ReactComponent as HeroLightSVG } from "./HeroLight.svg";
import { ReactComponent as HeroIconSVG } from "./HeroIcon.svg";
import { ReactComponent as FeaturesLightSVG } from "./FeaturesLight.svg";
import { ReactComponent as StatsLightSVG } from "./StatsLight.svg";
import { ReactComponent as TelebotIMG } from "./TelebotIMG.svg";
import { ReactComponent as WalletSVG } from "./Wallet.svg";
import { ReactComponent as FooterLogoSVG } from "./FooterLogo.svg";
import { ReactComponent as TwitterSVG } from "./Twitter.svg";
import { ReactComponent as MediumSVG } from "./Medium.svg";
import { ReactComponent as DiscordSVG } from "./Discord.svg";
import { ReactComponent as OtherIconSVG } from "./Idk.svg";
import { ReactComponent as FooterIconSVG } from "./FooterIcon.svg";
import { ReactComponent as HamburgerSVG } from "./Hamburger.svg";
import { ReactComponent as HeroBGSVG } from "./HeroBG.svg";
import { ReactComponent as FeaturesIconSVG } from "./FeaturesIcon.svg";
import { ReactComponent as TokenomicsGearSVG } from "./TokenomicsGear.svg";
import { ReactComponent as TelebotCirleSVG } from "./TelebotCirle.svg";
import { ReactComponent as HowToBuyGearSVG } from "./HowToBuyGear.svg";
import { ReactComponent as RoadmapGearSVG } from "./RoadmapGear.svg";
import { ReactComponent as RoadmapBlockSVG } from "./RoadmapBlock.svg";
import { ReactComponent as RoadmapBGSVG } from "./RoadmapBG.svg";
import { ReactComponent as TelegramSVG } from "./telegram.svg";
import { ReactComponent as DexScreenerSVG } from "./dex-screener.svg";

export const SVGs = {
  HeaderLogoSVG,
  HeroLightSVG,
  HeroIconSVG,
  FeaturesLightSVG,
  StatsLightSVG,
  TelebotIMG,
  WalletSVG,
  FooterLogoSVG,
  TwitterSVG,
  MediumSVG,
  DiscordSVG,
  OtherIconSVG,
  FooterIconSVG,
  HamburgerSVG,
  HeroBGSVG,
  FeaturesIconSVG,
  TokenomicsGearSVG,
  TelebotCirleSVG,
  HowToBuyGearSVG,
  RoadmapGearSVG,
  RoadmapBlockSVG,
  RoadmapBGSVG,
  TelegramSVG,
  DexScreenerSVG,
};
