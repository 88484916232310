import React, { useState } from "react";
import { motion } from "framer-motion"; // Import Framer Motion
import { SVGs } from "../assets/svg";

// Animation variants
const headerVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1, transition: { duration: 0.8, ease: "easeInOut" } },
};

const logoVariants = {
  hidden: { opacity: 0, x: -100 },
  visible: { opacity: 1, x: 0, transition: { duration: 1 } },
};

const navItemVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: (i) => ({
    opacity: 1,
    y: 0,
    transition: { delay: i * 0.1, duration: 0.6 },
  }),
};

const buttonVariants = {
  hidden: { opacity: 0, x: 100 },
  visible: { opacity: 1, x: 0, transition: { duration: 1 } },
};

const barVariants = {
  hidden: { width: "0%" },
  visible: { width: "75%", transition: { duration: 1.5, ease: "easeInOut" } },
};

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <motion.header
      className="relative w-full bg-[#020913] z-20"
      variants={headerVariants}
      initial="hidden"
      animate="visible"
    >
      {/* Bottom bar animation */}
      <motion.div
        className="header-bottom-bar"
        variants={barVariants}
        initial="hidden"
        animate="visible"
      ></motion.div>

      <div className="lg:px-12 md:px-8 px-4 py-4 flex justify-between items-center">
        {/* Logo translation from left to right */}
        <motion.div
          className="flex items-center"
          variants={logoVariants}
          initial="hidden"
          animate="visible"
        >
          <SVGs.HeaderLogoSVG />
        </motion.div>

        {/* Navigation links with fade-in effect */}
        <nav className="navbar space-x-6 text-[18px] font-sans">
          {["Home", "Features", "Tokenomics", "Roadmap", "Whitepaper"].map(
            (item, index) => (
              <motion.a
                key={item}
                href="#"
                className="text-transparent text-[18px] font-bold font-inria bg-clip-text bg-gradient-to-r hover:text-[#13FB72] border-b-2 border-transparent hover:border-[#13FB72] transition duration-300 ease-in-out"
                custom={index}
                initial="hidden"
                animate="visible"
                variants={navItemVariants}
              >
                {item}
              </motion.a>
            )
          )}
        </nav>

        {/* Button animation from right to left */}
        <motion.div
          className="header-btn"
          initial="hidden"
          animate="visible"
          variants={buttonVariants}
        >
          <button
            className="relative inline-block px-8 py-2 text-[18px] text-black bg-button-gradient hover:bg-button-linear transition-all ease-out duration-400 font-sans transform skew-x-[-20deg] border-none"
            style={{
              backgroundSize: "175% 100%",
              backgroundPosition: "center",
              clipPath: "polygon(10% 0%, 100% 0%, 90% 100%, 0% 100%)",
            }}
          >
            <span className="inline-block whitespace-nowrap transform skew-x-[20deg]">
              Download App
            </span>
          </button>
        </motion.div>

        <div className="nav-collapse-btn hidden">
          <button
            className="text-gray-700 focus:outline-none"
            onClick={() => setIsOpen(!isOpen)}
          >
            <SVGs.HamburgerSVG className="w-[36px] h-[36px]" />
          </button>
        </div>
      </div>

      <div
        className={`absolute sm:px-8 px-4 py-4 top-full left-0 w-full flex items-center justify-evenly md:justify-between bg-[#020913] md:bg-transparent shadow-md overflow-hidden transition-all duration-300 ease-in-out transform origin-top ${
          isOpen ? "opacity-100 scale-y-100" : "opacity-0 scale-y-0"
        }`}
      >
        <div className="flex md:flex-row flex-col justify-start items-center gap-6">
          {["Home", "Features", "Tokenomics", "Roadmap", "Whitepaper"].map(
            (item, index) => (
              <motion.a
                key={item}
                href="#"
                className="text-transparent text-[18px] font-bold font-inria bg-clip-text bg-gradient-to-r hover:text-[#13FB72] border-b-2 border-transparent hover:border-[#13FB72] transition duration-300 ease-in-out"
                custom={index}
                initial="hidden"
                animate="visible"
                variants={navItemVariants}
              >
                {item}
              </motion.a>
            )
          )}
        </div>
        <motion.button
          className="relative inline-block px-8 py-2 text-[18px] text-black bg-button-gradient hover:bg-button-linear transition-all ease-out font-sans transform skew-x-[-20deg] border-none"
          style={{
            backgroundSize: "175% 100%",
            backgroundPosition: "center",
            clipPath: "polygon(10% 0%, 100% 0%, 90% 100%, 0% 100%)",
          }}
          initial="hidden"
          animate="visible"
          variants={buttonVariants}
        >
          <span className="whitespace-nowrap transform skew-x-[20deg]">
            Download App
          </span>
        </motion.button>
      </div>
    </motion.header>
  );
};

export default Header;
