import { motion } from "framer-motion"; // Import Framer Motion for animations
import React from "react";
import VideoPlaceholder from "../assets/png/video-placeholder.png";
import { SVGs } from "../assets/svg";

// Animation variants
const leftSideVariants = {
  hidden: { opacity: 0, x: -50 },
  visible: { opacity: 1, x: 0, transition: { duration: 0.8, ease: "easeOut" } },
};

const rightSideVariants = {
  hidden: { opacity: 0, scale: 0.8 },
  visible: {
    opacity: 1,
    scale: 1,
    transition: { duration: 0.8, ease: "easeOut" },
  },
};

const Telebot = () => {
  return (
    <div className="relative flex flex-col md:flex-row w-full items-center justify-between gap-12 px-8 md:px-12 py-16 md:py-24">
      {/* Left side with translation animation */}
      <motion.div
        className="flex flex-col gap-4 text-center md:text-left max-w-full md:max-w-[50%]"
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.3 }}
        variants={leftSideVariants} // Apply translate effect to the left side
      >
        <h1 className="relative text-[24px] md:text-[32px] font-semibold text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-purple-500 font-['Noto Serif Thai'] leading-tight">
          <SVGs.TelebotCirleSVG className="absolute top-0 left-0 translate-y-[-50%] -z-50 opacity-35 sm:scale-100 scale-75" />
          Watch Our Vision
          <br />
          Come to Life
        </h1>

        <p className="text-[14px] md:text-[16px] font-normal text-[#c4efea]">
          At Sudo Lab, we aim to build a platform that offers everything
          individuals and businesses need to level up their digital presence
          effortlessly. Our AI-powered Telegram bot provides a host of services,
          including domain management, containerization, hosting, RDP solutions,
          blockchain node deployment, NaaS, IoT and data solutions, cloud
          solutions, and website development, all designed to boost your
          productivity.
        </p>

        <p className="text-[14px] md:text-[16px] font-normal text-[#c4efea]">
          Additionally, we're planning to develop a DApp soon to integrate these
          services. With Sudo Lab, reduce the hassle of switching platforms and
          boost your productivity by 70%. Don’t wait any longer—get started to
          achieve extraordinary results with minimal effort!
        </p>

        <div className="inline-flex">
          <button
            className="relative px-8 py-2 mx-auto md:mx-0 text-black bg-button-gradient hover:bg-button-linear transition-all ease-out duration-400 w-auto transform skew-x-[-30deg] border-none"
            style={{
              backgroundSize: "175% 100%",
              backgroundPosition: "center",
            }}
          >
            <span className="inline-block transform skew-x-[30deg]">
              Launch Telegram Bot
            </span>
          </button>
        </div>
      </motion.div>
      <motion.img
        alt="telebot-video"
        src={VideoPlaceholder}
        className="w-[100%] md:w-[50%] mt-8 md:mt-0 rounded-[20px]"
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.3 }}
        variants={rightSideVariants}
      ></motion.img>
    </div>
  );
};

export default Telebot;
