import React from "react";
import Header from "./components/header";
import Hero from "./components/hero";
import "./app.css";
import Features from "./components/features";
import Tokenomics from "./components/tokenomics";
import Telebot from "./components/telebot";
import HowToBuy from "./components/howtobuy";
import Roadmap from "./components/roadmap";
import Footer from "./components/footer";

function App() {
  return (
    <div className="relative overflow-x-clip">
      <div className="features-light"></div>
      <div className="features-light-2"></div>
      <div className="howtobuy-light"></div>
      <div className="howtobuy-light-2"></div>
      <div className="roadmap-light"></div>
      <Header />
      <Hero />
      <Features />
      <Tokenomics />
      <Telebot />
      <HowToBuy />
      <Roadmap />
      <Footer />
    </div>
  );
}

export default App;
